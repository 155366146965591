import React, { useState, useEffect } from "react";
import Steps from "./steps";
import FormikWizard from "formik-wizard";
import useWindowResize from "../../../hooks/useWindowResize";
import newLogoSmartAsset from "../../../images/newLogoSmartAsset.svg";
import { isLoggedIn } from "../../../common/utils/cookie-utils";
import config from "../../../../data/SiteConfig";
import CircularLoading from "../../../common/components/CircularLoading/CircularLoading";
import { setIsApproved, setToken } from "../../../common/utils/auth-utils";
import { setAcl } from "../../../common/utils/acl-utils";
import _ from "lodash";
import { createPassword as createPasswordApi } from "../../../common/constants/api";
import { navigate } from "gatsby";
import { setUserDetailsInfo } from "../../../common/utils/userDetailsInfo";

function FormWrapper({ children, canGoBack, wizard, steps, values }) {
  const [windowWidth, windowHeight] = useWindowResize();
  const [updatingChange, setUpdatingChange] = useState(false);
  useEffect(() => {
    setUpdatingChange(true);
    if (isLoggedIn()) {
    }
    if (config?.invitationId && config.moveNextBP) {
      values.login.mobile = config?.developerUsername;
      config.moveNextBP = false;
      wizard.push(steps[1]);
    }
    setUpdatingChange(false);
  }, []);
  return (
    <div className=" w-full  md:min-h-full max-h-full   md:h-screen  overflow-auto scrolling-auto bg-navy-600 relative py-2 ">
      <div
        className=" w-full max-w-sm md:max-w-md mx-auto py-5 min-h-full  bg-white  "
        style={{
          ...(windowWidth > 767
            ? {
                height: "min-content",
                marginTop: "100px",
                minHeight: "auto",
              }
            : {
                minHeight: "100vh",
              }),
        }}
      >
        <div className="relative  flex flex-row items-center h-auto md:h-auto  max-h-full justify-center space-x-3 max-w-full p-2 ">
          <div className="flex flex-col place-self-start content-center w-full h-full md:h-auto px-4">
            <div className="flex  items-center justify-center">
              <img
                className="block mx-auto  "
                src={newLogoSmartAsset}
                alt="Fincity"
              />
            </div>
            <div
              className="font-bold text-base xxs:text-lg md:text-xl mt-4 mb-2 mx-auto text-center max-w-full"
              style={{
                color: "#394759",
              }}
            >
              Developer Onboarding
            </div>
            {updatingChange ? <CircularLoading /> : children}
          </div>
        </div>
      </div>
    </div>
  );
}
const Index = () => {
  const handleSubmit = React.useCallback(async values => {
    await createPassword(values?.confirmPassword, values?.login);
  }, []);
  let createPassword = (sectionValues, formValues) => {
    if (sectionValues?.password) {
      createPasswordApi(
        sectionValues?.password,
        config?.forgotPassTempToken?.token
      )
        .then(async res => {
          let [id, token, username, userType, name] = [
            res?.id,
            res?.token,
            res?.username,
            res?.userType,
            res?.name,
          ];
          await setToken({ id, token, username, userType });
          setAcl(res?.acl);
          let obj = {
            username: username,
            name: name,
          };
          setIsApproved(res?.checks?.isApproved);
          setUserDetailsInfo(obj);
          navigate("/app/dashboard");
        })
        .catch(err => {
          return Promise.reject(err?.response?.data);
        });
    }
  };
  useEffect(() => {
    isLoggedIn() ? navigate("/app/dashboard") : null;
  });
  return (
    <FormikWizard steps={Steps} onSubmit={handleSubmit} render={FormWrapper} />
  );
};
export default Index;
