import React, { useState, useEffect, Fragment } from "react";
import DeveloperOnBoarding from "../../containers/Developer/OnBoarding/index";
import { enableDeveloperOnboarding } from "gatsby-env-variables";
import config from "../../../data/SiteConfig";
import { developerInvitationCheck } from "../../common/constants/api";
import withErrorHandler from "../../hoc/withErrorHandler/withErrorHandler";
import CircularLoading from "../../common/components/CircularLoading/CircularLoading";
import { navigate } from "gatsby";
import Toast from "../../common/components/Toast/Toast";
import Logout from "../../common/components/Logout/Logout";
import { isLoggedIn } from "../../common/utils/cookie-utils";
const Developer = props => {
  let id = props.params.id;
  const [updatingChange, setUpdatingChange] = useState(false);
  useEffect(() => {
    setUpdatingChange(true);
    developerInvitationCheck(id)
      .then(data => {
        if (data?.askForPassword) {
          config.invitationId = id;
          config.developerUsername = data?.username;
          setUpdatingChange(false);
        } else {
          Toast("info", "You have already registered!", 3000, "top-right");
          if (isLoggedIn())
            Logout({ hardReload: false, routeTo: "/developer/login" });
          else navigate("/developer/login");
        }
      })
      .catch(err => {});
  }, []);

  return (
    <Fragment>
      {updatingChange ? (
        <div className="flex h-screen align-center justify-center">
          <CircularLoading />
        </div>
      ) : enableDeveloperOnboarding ? (
        <DeveloperOnBoarding />
      ) : (
        ""
      )}
    </Fragment>
  );
};
export default Developer;
