import React from "react";
import { object, string, ref } from "yup";
import GlobalOtp from "../../../common/components/GlobalOtpComp/GlobalOtpComp";
import ConfirmPassword from "../../../components/CreatePassword/CreatePassword";
import {
  sendOtpToBp,
  createPassword as createPasswordApi,
  ConfirmOTPForgotPassword,
} from "../../../common/constants/api";
import config from "../../../../data/SiteConfig";
import EnterMobNo from "../../BP/BPOnBoarding/components/EnterMobNo";
import { navigate } from "gatsby";
const Steps = [
  {
    id: "login",
    component: EnterMobNo,
    initialValues: {
      mobile: "",
    },
    validationSchema: object().shape({
      mobile: string()
        .required("Required")
        .max(10)
        .matches(/([6-9]\d{9}$)/, "Mobile should be 10 digit number"),
    }),
    onAction: async (sectionValues, formValues) => {
      await sendOtpToBp(sectionValues?.mobile)
        .then(res => {})
        .catch(err => {
          return Promise.reject(err?.response?.data);
        });
    },
  },
  {
    id: "otp",
    component: () => (
      <GlobalOtp
        header={
          <p
            className=""
            style={{
              color: "#8295A7",
            }}
          >
            Enter the OTP you received to
          </p>
        }
        consumerSendOtp={sendOtpToBp}
      />
    ),
    initialValues: {
      otp: "",
    },
    onAction: async (sectionValues, formValues) => {
      try {
        let body = {
          username: formValues?.login?.mobile,
          otp: sectionValues?.otp,
          userType: 2,
        };
        let tokenCredentials = await ConfirmOTPForgotPassword(body);
        config.forgotPassTempToken = tokenCredentials;
      } catch (err) {
        throw new Error(
          err.response.data.message || "Oops! something went wrong !!"
        );
      }
    },
  },
  {
    id: "confirmPassword",
    component: ConfirmPassword,
    initialValues: {
      password: "",
      confirmPassword: "",
    },
    validationSchema: object().shape({
      password: string()
        .min(8, "Password must be at least 8 characters")
        .max(15, "Password must not be longer than 15 characters")
        .required("Password is required")
        .matches(
          /[!@#$%^&*()\-_=+{};:,<.>]/g,
          "Must contain an Special Charecter"
        ),
      confirmPassword: string()
        .min(8, "Please confirm your password")
        .required("Must match the password above ")
        .oneOf([ref("password"), null], "Passwords must match"),
    }),
  },
];
export default Steps;
